<template>
    <router-view></router-view>
</template>

<script>
import {Page,Toolbar,Edit} from "@syncfusion/ej2-vue-grids";
import { L10n,setCulture,loadCldr } from "@syncfusion/ej2-base";
// 中文化
import EJ2_LOCALE from './assets/json/zh.json';
setCulture("zh");
import Keycloak from 'keycloak-js';

loadCldr(
    require('cldr-core/supplemental/numberingSystems.json'),
    require('cldr-dates-full/main/zh/ca-generic.json'),
    require('cldr-dates-full/main/zh/dateFields.json'),
    require('cldr-dates-full/main/zh/ca-gregorian.json'),
    require('cldr-dates-full/main/zh/timeZoneNames.json'),
    require('cldr-core/supplemental/weekData.json'),
);

L10n.load({ zh: EJ2_LOCALE.zh });

  export default {
    name: 'App',
    provide: {
      grid: [Page, Edit, Toolbar]
    },
    created() {
      // 在页面加载时读取sessionStorage里的状态信息
      if (sessionStorage.getItem('store')) {
        this.$store.replaceState(
            Object.assign(
                {},
                this.$store.state,
                JSON.parse(sessionStorage.getItem('store'))
            )
        )
      }

      // 在页面刷新时将vuex里的信息保存到sessionStorage里
      // beforeunload事件在页面刷新时先触发
      window.addEventListener('beforeunload', () => {
        sessionStorage.setItem('store', JSON.stringify(this.$store.state))
      })
    },
    mounted() {
			// this.login();
		},
		methods:{
			login(){
        let vm=this;
				let kUrl= process.env.NODE_ENV == "development" ? window.gDev.KeyCloakUrl :window.gEnv.KeyCloakUrl;
          let kConfig=process.env.NODE_ENV == "development" ? window.gDev.KeyCloakConfig :window.gEnv.KeyCloakConfig;
          const keycloak = new Keycloak({
              url: kUrl,    // 这里需要写你链接的地址
              realm: kConfig.realm,                             // 链接的领域
              clientId: kConfig.clientId,              // 链接的客户端
          })
          keycloak.init({onLoad:'login-required',CheckLoginIframe:false}).then((auth)=>{
            let roles = keycloak.tokenParsed.realm_access.roles
            if(roles.includes('IIoTAdmin')||roles.includes('IIoTUser')||roles.includes('IIoTControl') ) { // 登录用户的权限不包含这三个权限中的一个，不能登录，跳回登录页
              if(!auth){
                  window.location.reload()
              } else {
                let time = (keycloak.idTokenParsed.exp - keycloak.idTokenParsed.iat -2) * 1000
                setInterval(() => {
                  keycloak.updateToken(-1).then((refreshed) => {
                    if (refreshed) {
                      this.$store.commit('setOdataHeaders',keycloak.token);
                      console.debug('Token refreshed');
                    } else {
                      console.warn('Token not refreshed');
                    }
                  })
                }, time)
                keycloak.loadUserProfile().then((profile)=>{
                  this.$store.commit('setUserName',profile.username)
                  // console.log('登录',profile.username);
                  this.$http.get(this.$apiPath.employee + '/loginName/' + profile.username).then(res => {
                    if (res.status==200 && res.data.code==200) {
                      this.$store.commit('setUserInfo',res.data.data);
                    }else {
                      vm.$message({
                        type: 'warning',
                        message: '找不到该账号的员工信息，请联系管理员！'
                      })
                    }
                  })
                })
                this.$store.commit('setKeycloakList',keycloak);
                this.$store.commit('setOdataHeaders',keycloak.token);
                this.$store.commit('setExp',keycloak.exp);
                this.$store.commit('SetRole',keycloak.tokenParsed.realm_access.roles);
              }
            } else {
                let logoutUrl= process.env.NODE_ENV == "development" ? window.gDev.KeyCloakLogoutUrl :window.gEnv.KeyCloakLogoutUrl;
                keycloak.logout({redirectUri: logoutUrl})
            }
        })
			},
		}
  }
</script>

<style>
/* 基本样式设置 */
@import "assets/css/index.scss";

/* elementUI设置 */
@import "assets/css/elementStyle.css";

/* syncfusion */
@import "@syncfusion/ej2-base/styles/material.css";
@import "@syncfusion/ej2-vue-navigations/styles/material.css";
@import "@syncfusion/ej2-buttons/styles/material.css";
@import "@syncfusion/ej2-vue-popups/styles/material.css";
@import "@syncfusion/ej2-vue-grids/styles/material.css";
@import '@syncfusion/ej2-vue-layouts/styles/material.css';
@import "@syncfusion/ej2-base/styles/material.css";
@import "@syncfusion/ej2-inputs/styles/bootstrap5.css";
@import "@syncfusion/ej2-vue-dropdowns/styles/material.css";
@import "@syncfusion/ej2-calendars/styles/material.css";
@import '@syncfusion/ej2-vue-calendars/styles/material.css';
@import "@syncfusion/ej2-vue-schedule/styles/material.css";

html,
body {
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*height: 100%;*/
  /*width: 100%;*/
  /*background-color: #01042A;*/
}
</style>
