export default {
    mounted(el, binding) {
        // 鼠标移入时，将浮沉元素插入到body中
        el.onmouseenter = function (e) {
            // 创建浮层元素并设置样式
            const vcTooltipDom = document.createElement("div");
            vcTooltipDom.style.cssText = "position:absolute;background: #15183A;padding:10px;border-radius:5px;color:#F7F8F8;font-size:14px;z-index:3001";
            // 设置id方便寻找
            vcTooltipDom.setAttribute("id", "v-tooltipText");
            // 将浮层插入到body中
            document.body.appendChild(vcTooltipDom);
            // 浮层中的文字 通过属性值传递动态的显示文案
            document.getElementById("v-tooltipText").innerHTML = binding.value.text;
        };
        // 鼠标移动时，动态修改浮沉的位置属性
        el.onmousemove = function (e) {
            const vcTooltipDom = document.getElementById("v-tooltipText");
            let width= vcTooltipDom.clientWidth;
            let height=vcTooltipDom.clientHeight;
            let clientX=e.clientX,clientY=e.clientY;
            let offset=15;
            let elem = document.getElementById(binding.value.id);
            let bottom = elem.getBoundingClientRect().bottom;
            let right = elem.getBoundingClientRect().right;
            vcTooltipDom.style.top = clientY + offset + "px";
            vcTooltipDom.style.left = clientX + offset + "px";
            if((clientY+offset+height)>bottom){
                vcTooltipDom.style.top = (clientY - height -offset ) + "px";
            }
            if((clientX+offset+width)>right){
                vcTooltipDom.style.left = (clientX - width -offset ) + "px";
            }
            // vcTooltipDom.style.top = e.clientY +window.scrollY+ 15 + "px";
            // vcTooltipDom.style.left = e.clientX + 15 + "px";
        };
        // 鼠标移出时将浮层元素销毁
        el.onmouseleave = function () {
            // 找到浮层元素并移出
            const vcTooltipDom = document.getElementById("v-tooltipText");
            vcTooltipDom && document.body.removeChild(vcTooltipDom);
        };
    },
    unmounted(el, binding) {}
}
