// 配置url
const baseUrl = process.env.NODE_ENV == "development" ? window.gDev.ApiUrl : window.gEnv.ApiUrl;   // Erp接口
const baseUrlC = process.env.NODE_ENV == "development" ? window.gDev.baseUrlC : window.gEnv.baseUrlC;  // 远控地址
const baseUrlE = process.env.NODE_ENV == "development" ? window.gDev.baseUrlE : window.gEnv.baseUrlE;  // 远控地址
const baseUrlP = process.env.NODE_ENV == "development" ? window.gEnv.baseUrlP : window.gEnv.baseUrlP;  // 远控地址

// const baseUrl1 = "http://192.168.3.198:6001/api/v1/ScreenShow/";   // 旧版 运维接口

let apiPath = {

    // 数据填报
    ChemicalUseLines:baseUrlE+'ChemicalUseLines',//用药记录
    UserLoginInfo:baseUrlE+'UserLoginInfo',  //人员登录信息
    StationInfo:baseUrlE+'StationInfo',  //站点信息
    StationReporter:baseUrlE+'StationReporter',// 化验数据填报人员
    StationAcqItem:baseUrlE+'StationAcqItem',//化验数据条目
    StationAcqData:baseUrlE+'StationAcqData',//化验数据
    ChemicalReporter:baseUrlE+'ChemicalReporter', //用药数据填报人员
    ChemicalNames:baseUrlE+'ChemicalNames',  // 药品分类
    MaintenanceReporter:baseUrlE+'MaintenanceReporter', //维修处置填报人员
    MaintenanceManager:baseUrlE+'MaintenanceManager',//维修管理
    MaintenanceType:baseUrlE+'MaintenanceType',//维修分类
    Stationleader:baseUrlE+'Stationleader',  // 站点负责人
    StationDutyArrange:baseUrlE+'StationDutyArrange',  // 站点值班人
    SystemAlarm:baseUrlE+'SystemAlarm', // 系统报警信息
    SystemAlarmHandler:baseUrlE+'SystemAlarmHandler',  // 系统报警处理
    MaintenanceReportLines:baseUrlE+'MaintenanceReportLines',//报修信息
    MaintenanceProcessLines:baseUrlE+'MaintenanceProcessLines',//报修信息

    CarbonDayReduction1:baseUrlE+'CarbonDayReduction',  // 碳减排日数据
    CarbonMonthReduction1:baseUrlE+'CarbonMonthReduction',  // 碳减排月数据


    // 站点图表样式
    UITopicMapDataKey:baseUrlE+'UITopicMapDataKey',//数据与消息匹配列表
    HrDepartmentDetail:baseUrlE+'HrDepartmentDetail',//部门详情数据
    UiConfigParameter:baseUrlE+'UiConfigParameter',//获取全部数据列表

    HrRelation: baseUrlE + 'HrRelation', // 公司-部门-员工-职位 关联关系

    //预测结果
    Predict:baseUrlP+'Tjai/predict',//获取预测功能


    // 远控
    RemoteCtl:baseUrlC+'RemoteCtl',



    // ----------------- Erp关联接口 --------------------岳
    weatherDayUrl: baseUrl+'api/WeatherQuality/city/',  // 天气接口
    appendix:baseUrl+'api/appendix',  // 附件

    // 组织架构
    hrRelation: baseUrl + 'api/HrRelation', // 组织关系
    employee: baseUrl + 'api/Employee', // 员工信息

    // 站点信息
    station:baseUrl+'api/station',   // 站点信息
    stationLevel:baseUrl+'api/stationLevel',  // 站点层级
    stationConfig:baseUrl+'api/stationConfig',  // 站点配置
    stationPage:baseUrl+'api/stationPage',  // 站点单页面-页面配置
    stationGridItem:baseUrl+'api/stationGridItem',  // 站点单页面-网格数据配置
    mqtttopickeytolabel:baseUrl+'api/mqtttopickeytolabel',  // 站点主题与消息匹配表

    // 碳减排
    carbonMonthReduction:baseUrl+'api/CarbonMonthReduction',  // 碳减排月数据
    carbonDayReduction:baseUrl+'api/CarbonDayReduction',  // 碳减排日数据

    // 工作流相关
    stationWarnValue:baseUrl+'api/stationWarnValue',  // 预警报警信息





























    // ---------- 弃用部分  ---------------
    /*
    // 巡检记录
    LineInspectRecordUrl: baseUrl1+"LineInspectRecord(0)",
    // 巡检处置
    // LineInspectHandleUrl: baseUrl1+"LineInspectHandle",
    // 报警
    AlarmRecordUrl: baseUrl1+"AlarmRecord(0)",
    // 班组签到
    CheckInOutUrl: baseUrl1+"CheckInOut",
    // 系统通信状态
    CommunicationStatusUrl: baseUrl1+"CommunicationStatus",
    // 设备状态
    DeviceStatusUrl: baseUrl1+"DeviceStatus",
    // 用药记录
    ReagentAmountUrl: baseUrl1+"ReagentAmount",
    // 药品库存
    ReagentStockUrl: baseUrl1+"ReagentStock",
    // 报修
    RepairRecordUrl: baseUrl1+"RepairRecord",
    // 备品备件
    SparePartsUrl: baseUrl1+"SpareParts",
    // 车辆管理
    VehicleRecordUrl: baseUrl1+"VehicleRecord",

     */

};

export default apiPath;
