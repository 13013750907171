import { createStore } from 'vuex';

const store = createStore({
    // 全局state
    state () {
        return {
            userInfo:{},
            userRoles:[],
            tokenInfo:{},
            odataHeaders:[
                {Authorization:'Bearer '}
            ],
			roles: [], // 角色操作权限
            yMaxData:{},// y轴范围
            xMaxData:{},//X轴范围
            keycloakList:{},
            userName:'',
            exp:0,
            siteOptions:[], //所有站点相关名称数据
            pageStatus:'',
            allEchartsQueues:{},
            isHome:true,
            nowStation: {},
            dataQueueAll:{},   // 所有主题消息信号--队列形式
            dataSingleAll:{},  // 所有主题消息信号--最新一条记录

        }
    },
    mutations:{
        setPageStatus(state:any,value){
            state.pageStatus = value
        },
        setSiteOptions(state:any,value){
            state.siteOptions = value
        },
        setUserInfo(state,data){
            // console.log(data);
            if(data==''){
                state.userInfo={};
                state.userRoles=[];
            }else{
                state.userInfo= {
                    userId:data.id,
                    userName:data.name,
                    workerNumber:data.workerNumber,
                    loginName:data.loginName,
                    companyId:data.companyId,
                    companyName:data.companyName,
                    departmentId:data.departmentId,
                    departmentName:data.departmentName,
                    businessRoles:data.businessRoles,
                    businessRolesName:data.businessRolesName,
                };
                state.userRoles=data.businessRolesName;
            }
		},
        setTokenInfo(state,data){
            // console.log(data);
            if(data==''){
                state.tokenInfo={};
            }else{
                state.tokenInfo= {
                    "access_token":data["access_token"],
                    "refresh_token":data["refresh_token"],
                    "token_type":data["token_type"],
                    "expires_in":data["expires_in"],
                    "refresh_expires_in":data["refresh_expires_in"],
                };
            }
        },
        setIsHome(state,data){
			state.isHome=data
		},
        setNowStation(state,data){
            state.nowStation= {
                type:data.type,
                value:data.value,
                isCheck:data.isCheck,
                isControlled:data.isControlled
            };
        },
        setDataQueueAll(state:any,data){
            // state.dataQueueAll=JSON.parse(JSON.stringify(data));
            state.dataQueueAll=data;
            // console.log(state.dataQueueAll);
        },
        setDataSingleAll(state:any,data){
            state.dataSingleAll=data;
            // console.log(state.dataSingleAll);
        },
        setOdataHeaders(state:any,token){
            state.odataHeaders[0].Authorization='Bearer '+token;
            window.localStorage.setItem('token',token);
        },
		SetRole(state:any,roleValue){ // 角色操作权限存储
			state.roles = roleValue;
		},
		setUserName(state:any,value){ //
			state.userName = value;
		},
		setExp(state:any,value){ //
			state.exp = value;
		},
		SetYMaxData(state:any,data){
			state.yMaxData = data;
		},
		SetXMaxData(state:any,data){
			state.xMaxData = data;
		},
        setKeycloakList(state,data){
            state.keycloakList=data
        },
    },
    actions:{

    },
    getters:{
        getOdataHeader(state:any){
            return state.odataHeaders;
        },
		getRole(state:any){ // 角色操作权限获取
			return state.roles[1];
		},
    },
    modules:{

    }
})

export default store;
